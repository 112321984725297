const heroLogo = document.getElementById("hero-logo");
const whatsappBtn = document.getElementById("whatsapp-btn");
const emailBtn = document.getElementById("email-btn");

function sendMessage() {
  const phoneNumber = "+447462808685";

  const message = "Hello! I am interested in your services.";
  const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
    message,
  )}`;

  window.open(whatsappURL);
}

// helper function to fade in elements.
function fadeIn(element, fadeTime) {
  element.style.opacity = 0;
  element.removeAttribute("hidden");
  setTimeout(() => {
    element.style.opacity = 1;
  }, fadeTime);
}

// on load
window.onload = () => {
  fadeIn(heroLogo, 1000);
  fadeIn(whatsappBtn, 1000);
  fadeIn(emailBtn, 1000);
};

// Event listeners
whatsappBtn.addEventListener("click", sendMessage);
